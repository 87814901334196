import { useEffect, useState } from "react";
import "../styles/slideshow.scss"

export default function Slideshow(){

    const images = [
        "h1.jpg",
        "h2.jpg",
        "h3.jpg"
    ];

    const [currentIndex, setCurrentIndex] = useState({"ind":0});

    const bgimage = {
        backgroundImage: "url("+images[currentIndex.ind]+")",
    };

    useEffect(()=>{
        images.forEach((picture) => {
            const img = new Image();
            img.src = picture;
        });
     },[]);

     function changePicture(){
        var newObj = {};
        Object.assign(newObj, currentIndex);
        newObj.ind++;
        if( newObj.ind == 3){
            newObj.ind = 0;
            setCurrentIndex(newObj);
        }
        else
        {
            setCurrentIndex(newObj);
        }
     }

     const CHANGE_MS = 4000;

    useEffect(() => {
        setTimeout(() => {
            
            changePicture();
        }, CHANGE_MS)
    },[currentIndex])


    return(
        <div id="slideshowheader">
            <div  className="slideshow-element">
                <div id="title">Trouvez un endroit où vous pouvez appeler votre propre</div>
                <img  src={images[currentIndex.ind]}/>
            </div>
        </div>
    )
}
