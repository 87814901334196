import SocialLinks from "./social-links";

export default function Footer(){

    return(
        <>
            <div className="footer">
                <div id="block">
                    <div id="title">
                        title
                    </div>
                    <div id="contacts">
                        <div>
                        text<br/>
                        </div>
                        <div> 
                        text<br/>
                        </div>
                        <div> 
                        text<br/>
                        </div>
                    </div>
                </div>
                <div id="block">
                    <div id="title">
                        title
                    </div>
                    <div id="contacts">
                        <div>
                        text<br/>
                        </div>
                        <div> 
                        text<br/>
                        </div>
                        <div> 
                        text<br/>
                        </div>
                    </div>
                </div>
                <div id="block">
                    <div id="title">
                        Our Services
                    </div>
                    <div id="contacts" >
                        <div className="clickable">
                        ACHETER
                        </div  >
                        <div className="clickable"> 
                        LOUER
                        </div>
                        <div className="clickable"> 
                        CONSTRUCTION
                        </div>
                    </div>
                </div>
                <div id="block">
                    <div id="title">
                        Contacter nous
                    </div>
                    <div id="contacts">
                        <div>
                        Email : <a href="mailto:admin@lmplatinumestates.cm">admin@lmplatinumestates.cm</a><br/>
                        </div>
                        <div> 
                            Calls/WhatsApp : <a href="tel:+237 692 85 05 84">+237 692 85 05 84</a><br/>
                        </div>
                        <div> 
                        Calls : <a href="tel:+237 651 12 50 90">+237 651 12 50 90</a> / <a href="tel:+237 658 91 74 77">+237 658 91 74 77</a><br/>
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="social-footer-icons">
                    <SocialLinks/>
            </div>
        </>
    )
}